import React from "react"
import ImageMeta from "../components/ImageMeta"
import Button from "../components/Buttons/Button"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Section, Container } from "../components/Containers"

const NotFoundPage = () => (
  <Layout language="en" navSpacerLarge>
    <SEO title="404 Error" description="404 page not found." robots="noindex" />

    <Section>
      <Container>
        <h1>404: Page not found</h1>
        <h2>Looks like this page is lost in the stars.</h2>
        <p>
          It looks like the page you are looking for is missing or no longer
          exists. Please check the URL to make sure it’s correct, or go back to
          the homepage.
        </p>
        <Button
          className="mt-3"
          href="/"
          buttonText="Home"
          internal
          appearance="outline-red"
        />
      </Container>
    </Section>
  </Layout>
)

export default NotFoundPage
